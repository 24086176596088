import React from "react";
import { GameProps } from "../../../components/GameView";
import FarmersWorldContract from "../model/FarmersWorldcontract";
import { timeHourToString } from "../../../model/Tools";


const FWBotView: React.FunctionComponent<GameProps> = props => {
  const fw = props.game as FarmersWorldContract;
  
  const tools = fw.tools.map((tool) => {
    const timeLeft = fw.getTimeLeft(tool);
    const hours = Math.floor(timeLeft / 3600)    
    const mins = Math.floor((timeLeft - hours * 3600) / 60);
    const secs = timeLeft - hours * 3600 - mins * 60;

    return (
      <tr key={tool.asset_id}>
        <td>{tool.config?.template_name}</td>
        <td>durability: {tool.current_durability + '/' + tool.durability}</td>
        <td>time: {String(hours).padStart(2, '0')+':'+String(mins).padStart(2, '0')+':'+String(secs).padStart(2, '0')}</td>
      </tr>
    )
  });

  const memberCards = fw.mbs.map((mb)=> {
    const timeLeft = fw.getTimeLeft(mb);
    const timeLeftString = timeHourToString(timeLeft);
    return (
      <tr key={mb.asset_id}>
        <td>{mb.config?.name}</td>
        <td>-</td>
        <td><span className="time-left">{timeLeftString}</span></td>
      </tr>
    )
  });

  return (
    <div>
      <div>Fee {fw.gameConfig.fee} %</div>
      <div>Enery : { fw.account.energy }</div>
      <table>
        <tbody>
          { tools}
          { memberCards }
        </tbody>
      </table>
    </div>
  )
}

export default FWBotView;