import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import FarmingTaleContract, { Animal } from "../model/FarmingTaleContract";

// export const CartMenuIcon = observer(({className, alt, selected}: MenuIconProps): React.ReactElement => {
interface GameProps {
    game: FarmingTaleContract
}


const AnimalsView = observer(({game}:GameProps): React.ReactElement => {
    
    const [lastRefresh, setLastRefresh] = useState(new Date());
    useEffect(() => {
        const ms = 5 * 1000;
        const id = setInterval(()=>{
            setLastRefresh(new Date());
        }, ms);
        return () => clearInterval(id);
    }, []);


    const TableHeaderRow = () => {
        return <tr><th>id</th><th>build</th><th>harvest</th></tr>;
    }

    const TableRow = ({data}:any) => {
        return data.map((data:Animal) =>
            <tr key={data.asset_id}>
                <td>{data.config?.label}</td>
                <td>{data.building_asset_id}</td>
            </tr>
        );
    }

    const Table = ({data}:any) => {
        console.log(data);
        return (
            <table>
            <TableHeaderRow />
            <TableRow data={data} />
            </table>
        );
    }
      
    return (
        <div>
            <h2>List of Animals { lastRefresh.toLocaleString() }</h2>
            <Table data={game.animals}></Table>
        </div>
    )
});

export default AnimalsView;

/*




const Table = ({data}) => {
  return (
    <table>
      <TableHeaderRow />
      <TableRow data={data} />
    </table>
  );
}
*/