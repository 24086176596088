import React from "react";
import { GameProps } from "../../../components/GameView";
import XpansionContract from "../model/XpansionContract";
import { timeHourToString } from "../../../model/Tools";

const XPBotView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as XpansionContract;
  const rent = contract.rentAssets;

  const rentAssets = rent.map((e) => {
    const timeLeft = contract.getTimeLeft(e.mint_time);

    return (
      <div key={e.asset_id}>
        {e.asset_id} - { timeHourToString(timeLeft) }
      </div>
    )
  })

  return (
    <div>      
      { contract.name }
      { rentAssets }
    </div>
  )
}

export default XPBotView;