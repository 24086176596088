const getRand = () => {
  const arr = new Uint8Array(8);
  for (let i = 0; i < 8; i++) {
    const rand = Math.floor(Math.random() * 255);
    arr[i] = rand;
  }
  return arr;
};

const toHex = (buffer: any) => {
  return [...new Uint8Array(buffer)]
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
};

export const hexToDecimalArray = (hex:string) => {
  // hex -> dec
  const a = [];
  for (let i = 0; i < hex.length; i+=2) {
    const h = hex.slice(i,i+2);
    a.push(parseInt(h, 16));
  }
  return new Uint8Array(a);;
}

  // dec -> hex
  //const num = 46;
  //console.log(num.toString(16));

/*
account = Uint8Arry 0 0 144 134 3 218 38 183
accountName = qwnho.wam
diificulty = 2
lastMinArr = Uint8Array 46,14,1,69,193,71,66,92
lastMineTransaction = 2e0e0145c147425c
m = getTime()

rand =  52  be  f5  f4  28  91  1d  17
        82  190 245 244 40  145 29  23

Found hash in 374017 iterations with 0,0,144,134,3,218,38,183 52bef5f428911d17, last = 1, hex_digest 00001214a50c17f711cea0891aeea2039e931790f2f7152133561692121d04c1 taking 397.344s
*/
export interface MiningResult {
  account: string,
  rand_str: string,
  hex_digest: string,
}

export default class Mining {

  public async mine(accountStr:string, lastMineTxStr:string) : Promise<MiningResult> {

    let account = new Uint8Array([0, 0, 144, 134, 3, 218, 38, 183]);
    let account_str = accountStr;
    let difficulty = 2;
    let last_mine_tx = lastMineTxStr.slice(0, 16); // '2e0e0145c147425c';
    let last_mine_arr = hexToDecimalArray(last_mine_tx); // new Uint8Array([46, 14, 1, 69, 193, 71, 66, 92]);

    account = account.slice(0, 8);
    const is_wam = account_str.substr(-4) === '.wam';

    let good = false, itr = 0, hash, hex_digest = '', rand_arr, last;
    console.log(`Performing work with difficulty ${difficulty}, last tx is ${last_mine_tx}...`);
    if (is_wam) {
      console.log(`Using WAM account`);
    }
    const start = (new Date()).getTime();
    while (!good) {
      rand_arr = getRand();
      // rand_arr = new Uint8Array([82,190,245,244,40,145,29,23])

      const combined = new Uint8Array(account.length + last_mine_arr.length + rand_arr.length);
      combined.set(account);
      combined.set(last_mine_arr, account.length);
      combined.set(rand_arr, account.length + last_mine_arr.length);

      hash = await crypto.subtle.digest('SHA-256', combined.slice(0, 24));
      hex_digest = toHex(hash);

      if (is_wam) {
        good = hex_digest.substr(0, 4) === '0000';
      }
      else {
        good = hex_digest.substr(0, 6) === '000000';
      }

      if (good) {
        if (is_wam) {
          last = parseInt(hex_digest.substr(4, 1), 16);
        }
        else {
          last = parseInt(hex_digest.substr(6, 1), 16);
        }
        good = good && (last <= difficulty);
      }
      itr++;

      if (itr % 1000000 === 0) {
        console.log(`Still mining - tried ${itr} iterations`);
      }

      if (!good) {
        hash = null;
      }

    }
    const end = (new Date()).getTime();
    const rand_str = toHex(rand_arr);

    console.log(`Found hash in ${itr} iterations with ${account} ${rand_str}, last = ${last}, hex_digest ${hex_digest} taking ${(end - start) / 1000}s`)
    const mine_work = { account: account_str, rand_str, hex_digest };

    return mine_work;
  }
}