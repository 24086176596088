import EOSClient from "../RPCClient";
import Asset from "./Asset";

/**
 * https://wax.bloks.io/account/atomicassets
 */

export default class AtomicAssetContract {

    private _assets:Asset[] = [];
    private _client: EOSClient;

    constructor(client:EOSClient) {
      this._client = client;
    }

    public async loadAssets(): Promise<Asset[]> {
        this._assets = [];
        await this.walkTAssets();
        return this._assets;
    }

    protected async walkTAssets(nextKey='') {
      const client = this._client;
        try {
            const results = await client.rpc.get_table_rows({
                json: true,
                code: 'atomicassets',
                scope: client.userAccount,
                table: 'assets',
                lower_bound: nextKey === '' ? '':nextKey,
                limit: 100,    
                reverse: false,
                show_payer: false
            }); 
            // console.log(results);
            const rows = results['rows'];
            rows.forEach((row)=> {
                //console.log(row);
                const asset = new Asset(row['asset_id']);
                asset.colectionName = row['collection_name'];
                asset.schemaName = row['schema_name'];
                asset.templateId = row['template_id'];
                asset.owner = client.userAccount;
                this._assets.push(asset);
            });
            if (results['next_key'] !== '') {
                // console.log('must continue to walk');
                await this.walkTAssets(results['next_key'])
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    

}
