import React from "react";
import { GameProps } from "../../../components/GameView";
import AnimalWorldContract from "../model/AnimalWorldContract";
import { timeHourToString } from "../../../model/Tools";

const ANWBotView: React.FunctionComponent<GameProps> = props => {
  const contract = props.game as AnimalWorldContract;
  const timeLeft = contract.getTimeLeft(contract.user.data![0].last_claim);
  return (
    <div>      
      { contract.name } - { timeHourToString(timeLeft) }
    </div>
  )
}

export default ANWBotView;