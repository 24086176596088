import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import FarmersWorldContract, { Tool } from "../model/FarmersWorldcontract";

// export const CartMenuIcon = observer(({className, alt, selected}: MenuIconProps): React.ReactElement => {

interface GameProps {
    game: FarmersWorldContract
}

interface AssetProps {
    asset: Tool
}


const ToolsView = observer(({game}:GameProps): React.ReactElement => {
    
    const [lastRefresh, setLastRefresh] = useState(new Date());

    useEffect(() => {
        const ms = 5 * 1000;
        const id = setInterval(()=>{
            setLastRefresh(new Date());
        }, ms);
        return () => clearInterval(id);
    }, []);

    const handleClaimTool = async (asset: Tool) => {
        console.log('handleClaimTool');
        // await game.claimTool(UserStore.getInstance().wax, asset.asset_id);
    }

    const TableHeaderRow = () => {
        return <tr><th>id</th><th>harvest</th></tr>;
    }

    const getTimeLeft = (tool: Tool) => { 
        const t = tool.next_availability - Date.now() / 1000;        
        return Math.round(t);
    }


    const TableRow = ({data}:any) => {
        const HarvestBtn = ({asset}:AssetProps ) => {
            return (
                <button onClick={() => handleClaimTool(asset)}>claim</button>
            )
        }
        return data.map((data:Tool) =>
            <tr key={data.asset_id}>
                <td>{data.config?.template_name}</td>
                <td>{ getTimeLeft(data) > 0 
                ? getTimeLeft(data)  
                : <div><HarvestBtn asset={data}></HarvestBtn></div>}</td>
            </tr>
        );
    }

    const Table = ({data}:any) => {
        console.log(data);
        return (
            <table>
            <TableHeaderRow />
            <TableRow data={data} />
            </table>
        );
    }

    return <div>
        <h1>Tools {lastRefresh.toLocaleString() }</h1>
        <Table data={game.tools}></Table>
    </div>;
});

export default ToolsView;

