
export default class Asset {
    protected _id: string = '';
    private _colectionName: string = '';
    private _ramPayer: string = '';
    private _schemaName: string = '';
    private _templateId: string = '';
    private _owner: string = '';
    private _immutableSerializedData: [] = [];
    private _mutableSerializedData: [] = [];
    
    constructor(id:string) {
        this._id = id;
    }

    public get id() {
        return this._id;
    }

    public get owner() {
        return this._owner;
    }

    public set owner(owner:string) {
        this._owner = owner;
    }
    
    public get templateId(): string {
        return this._templateId;
    }
    public set templateId(value: string) {
        this._templateId = value;
    }

    public get colectionName(): string {
        return this._colectionName;
    }

    public set colectionName(value: string) {
        this._colectionName = value;
    }
    
    public get ramPayer(): string {
        return this._ramPayer;
    }
    
    public set ramPayer(value: string) {
        this._ramPayer = value;
    }

    public get schemaName(): string {
        return this._schemaName;
    }
    
    public set schemaName(value: string) {
        this._schemaName = value;
    }
    
    public get immutableSerializedData(): [] {
        return this._immutableSerializedData;
    }
    public set immutableSerializedData(value: []) {
        this._immutableSerializedData = value;
    }

    public get mutableSerializedData(): [] {
        return this._mutableSerializedData;
    }
    
    public set mutableSerializedData(value: []) {
        this._mutableSerializedData = value;
    }

}