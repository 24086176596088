import GameContract, { HarvestAsset, TokenBalance, TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";

export interface ToolConf {
  template_name: string,
  schema_name: string,
  type: string,
  rarity: string,
  level: number,
  template_id: string,
  energy_consumed: number,
  durability_consumed: number,
  mints: string[],
  rewards: string,
  charged_time: number
};

export interface AssetWithAvailabilty {
  next_availability: number
}

export interface Tool extends AssetWithAvailabilty{
  asset_id: string,
  owner: string,
  type: string,
  template_id: number,
  durability: number,
  current_durability: number,
  config?: ToolConf
};

export interface Member extends AssetWithAvailabilty {
  asset_id: string,
  owner: string,
  type: string,
  template_id: number,
  unstaking_time: number,
  config?: MemberConfig
};

export interface MemberConfig {
  name: string,
  template_id: string,
  type: string,
  img: string,
  badge_img: string,
}

export interface FWGameAccount {
  account: string,
  balances?: TokenBalance[],
  energy: number,
}

export interface GameConfig {
  init_energy: number,	
  init_max_energy: number,
  reward_noise_min: number,
  reward_noise_max: number,
  min_fee: number,	
  max_fee: number,	
  last_fee_updated: number,
  fee: number
}

/**
 * https://wax.bloks.io/account/farmersworld
 * 
 * must get config to show fee
 * 
 */

export default class FarmersWorldContract extends GameContract {

  static NAME = 'farmersworld';
  static CONTRACT_NAME = 'farmersworld';
  static COLLECTION_NAME = 'farmersworld';
  static TOKEN_CONTRACT = 'farmerstoken'

  private _toolsConf: Map<string, ToolConf>;
  private _tools: Tool[];
  private _account: FWGameAccount;

  private _mbsConfig: Map<string, MemberConfig>;
  private _mbs: Member[];

  private _gameConfig:GameConfig = {
    init_energy: 0,	
    init_max_energy: 0,
    reward_noise_min: 0,
    reward_noise_max: 0,
    min_fee: 0,	
    max_fee: 0,	
    last_fee_updated: 0,
    fee: 0
  };

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmersWorldContract] constructor');

    this._name = FarmersWorldContract.NAME;
    this._contractName = FarmersWorldContract.NAME;
    this._tokenContract = FarmersWorldContract.TOKEN_CONTRACT;

    this._toolsConf = new Map();
    this._tools = [];

    this._mbsConfig = new Map<string, MemberConfig>();
    this._mbs = [];

    this._account = {
      account: '',
      energy: 0
    }

  }

  public static getToolTimeLeft(tool: Tool) {
    const t = tool.next_availability - Date.now() / 1000;
    return Math.round(t);
  }

  public loadAssets = async () => {
    //
    this._toolsConf = await this.loadConfTools();
    //
    this._tools = await this.loadTools();
    //
    this._mbsConfig = await this.loadMbsConfig();
    this._mbs = await this.loadMbs();

    await this.loadBalances();

    await this.loadGameConfig();

    await this.loadGameAccount();

    this.updated();

    //
    return this;
  }

  public reloadAssets = async() => {
    this._tools = await this.loadTools();
    this._mbs = await this.loadMbs();
    await this.loadBalances();
    await this.loadGameConfig();
    await this.loadGameAccount();
    this.updated(); // 1637253910230
  }


  public loadGameConfig = async () => {
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: FarmersWorldContract.NAME,
        table: 'config',
        limit: 1,
        reverse: false,
        show_payer: false
      });
      this._gameConfig = results['rows'][0];
    }
    catch (e) {
      console.error(e);
    }
  }

  public loadGameAccount = async() => {
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: FarmersWorldContract.NAME,
        table: 'accounts',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const row = results['rows'][0];
      this._account = {
        account: row['account'],
        energy: row['energy'],
        balances: []       
      }

      row['balances'].forEach((element:any) => {
        const a = element.split(' ');
        // console.warn(a, element);
        const tokenB = {
          symbol: a[1],
          value: parseFloat(a[0])
        };
        this._account.balances?.push(tokenB);
        this._tokens[tokenB.symbol] = tokenB;
      });

      // results['rows'][0];
    }
    catch (e) {
      console.error(e);
    }
  }

  public loadMbsConfig = async (): Promise<Map<string, MemberConfig>> => {
    const configurations: Map<string, MemberConfig> = new Map();
    const client = this._client;    
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: 'farmersworld',
        table: 'mbsconf',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }

    return configurations;
  }

  public loadMbs = async (): Promise<Member[]> => {
    const members: Member[] = [];
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: FarmersWorldContract.NAME,
        table: 'mbs',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner'];
        if (owner === client.userAccount) {
          const item: Member = row;
          members.push(item);
          if (this._mbsConfig.has(row['template_id'])) {
            item.config = this._mbsConfig.get(row['template_id']);
          }
        }
      });
    }
    catch (e) {
      console.error(e);
    }
    return members;
  }

  public loadTools = async (): Promise<Tool[]> => {
    const tools: Tool[] = [];
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: FarmersWorldContract.NAME,
        table: 'tools',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner'];
        if (owner === client.userAccount) {
          const t: Tool = row;
          tools.push(t);
          if (this._toolsConf.has(row['template_id'])) {
            t.config = this._toolsConf.get(row['template_id']);
          }
        }
      });
    }
    catch (e) {
      console.error(e);
    }
    return tools;
  }

  public loadConfTools = async (): Promise<Map<string, ToolConf>> => {
    const configurations: Map<string, ToolConf> = new Map<string, ToolConf>();
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmersWorldContract.NAME,
        scope: 'farmersworld',
        table: 'toolconfs',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }
    return configurations;
  }

  public get tools(): Tool[] {
    return this._tools;
  }

  public get mbs(): Member[] {
    return this._mbs;
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    const list: HarvestAsset[] = [];
    this._tools.forEach((tool) => {
      list.push({
        id: tool.asset_id,
        label: tool.config?.template_name!,
        timeLeft: 0,
        collectionName: FarmersWorldContract.COLLECTION_NAME,
      })
    })
    this._mbs.forEach((mbs) => {
      list.push({
        id: mbs.asset_id,
        label: mbs.config?.name!,
        timeLeft: 0,
        collectionName: FarmersWorldContract.COLLECTION_NAME,
      })
    })
    return list;
  }

  public getTimeLeft(asset: AssetWithAvailabilty) { 
    const t = asset.next_availability - Date.now() / 1000;        
    return Math.round(t);
  }

  public get account(): FWGameAccount {
    return this._account;
  }

  public get gameConfig() {
    return this._gameConfig;
  }

  public async autoActions(): Promise<TransactionAction[]> {
    
    const client = this._client;

    await this.reloadAssets();
    // return [];

    // check all toos
    const actions: TransactionAction[] = [];

    this._tools.forEach((tool) => {
      const timeLeft = Math.round(tool.next_availability - Date.now() / 1000);
      console.log(`tool ${tool.config?.schema_name} timeLeft: ${timeLeft}`);
      if (timeLeft < 0) {
        actions.push(
          this.createGameActions(
            FarmersWorldContract.NAME, 
            client.userAccount, 'claim', {
              owner: client.userAccount,
              asset_id: tool.asset_id
            }
          )
        );
      }
    });

    this._mbs.forEach((mbs) => {
      const timeLeft = Math.round(mbs.next_availability - Date.now() / 1000);
      console.log(`tool ${mbs.config?.name} timeLeft: ${timeLeft}`);
    });

    return actions;
    /*    
    if (actions.length !== 0) {
      console.log(`Some actions need to be done ! ${JSON.stringify(actions)}`);
      for (let idx in actions) {
        const action = actions[idx];
        await this.runTransaction(wax, action);
      }
      await this.reloadAssets(wax);
    }
    */
  }

  public async finalizeAction(action:any) {
    console.log('finalizeAction');
  }

}