import { WaxJS } from "@waxio/waxjs/dist";
import { Api, JsonRpc } from "eosjs";

export interface RPCClient {
  rpc: JsonRpc,
  api?: Api,
  userAccount: string,
}

export default class EOSClient implements RPCClient {
  protected _rpc:JsonRpc;
  protected _api:Api|undefined;
  protected _userAccount: string;

  constructor() {
    this._rpc = new JsonRpc('');
    this._api = undefined;
    this._userAccount = ''
  }

  public login() {

  }

  public get api(): Api|undefined {
    return this._api;
  }

  public get rpc(): JsonRpc {
    return this._rpc;
  }

  public get userAccount(): string {
    return this._userAccount;
  }

}

export class WaxClient extends EOSClient {

  static WAX_ACCOUNT:string = 'waxAccount';
  static WAX_PUB_KEYS:string = 'pubKeys';

  protected _wax: WaxJS;

  constructor() {
    super();

    const userAccount = localStorage.getItem(WaxClient.WAX_ACCOUNT) !== null ? localStorage.getItem(WaxClient.WAX_ACCOUNT)!:undefined;
    const pubKeys = localStorage.getItem(WaxClient.WAX_PUB_KEYS) !== null ? JSON.parse(localStorage.getItem(WaxClient.WAX_PUB_KEYS)!):undefined;
    
    // localStorage.getItem(['EOS6kTSU4hrcYS9rgf5VtW9Qp1ReEZDKauJDBqfG24XVePtAEQisV', 'EOS8UhZSLGoiUSifugc4x2LrLbKW6GwKKNzJbxtZBBChqcKbfV18G'];

    this._wax = new WaxJS({
      rpcEndpoint: 'https://chain.wax.io',
      userAccount: userAccount,
      pubKeys: pubKeys,
      tryAutoLogin: false,
    });
    
  }

  public async login() {
    try {
      const userAccount = await this._wax.login();
      const pubKeys = this._wax.pubKeys;
      localStorage.setItem(WaxClient.WAX_ACCOUNT, userAccount);
      localStorage.setItem(WaxClient.WAX_PUB_KEYS, JSON.stringify(pubKeys));
    }
    catch (e) {
      console.error(e);
    }
  }

  public get wax () {
    return this._wax;
  }

  public get api() {
    return this._wax.api;
  }

  public get rpc() {
    return this._wax.rpc;
  }

  public get userAccount() {
    return this._wax.userAccount;
  }

}