import cryptoRandomString from "crypto-random-string";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";

interface NFTOnGameEC {
  asset_id: string,
  storer: string,
  name_pa: string, 
  rarity: number,
  is_in_slot: number,
  lvl: number,
  steps: number,
  stake_count: number,
  timer: number,
  finalnumber: number
  weapon: number,
  whomgrent: number,
  timerrent: number,
  rentlvl: number,
  percprof: number,
  energy: number,
  save: number,
  clas: string,
  element: string
}

interface User {
  allsum: number,
  balance: number,
  inrent: number,
  max_slots: number,
  maxrank: number,
  refnum: number,
  slot_counts: any[],
  username: string,
}

export default class NftPandaContract extends GameContract {

  static NAME = 'nftpandawofg';
  static CONTRACT_NAME = 'nftpandawofg';  
  static COLLECTION_NAME = 'nftpandawaxp' 
  static TOKEN_CONTRACT = 'nftpandabamb'

  protected _user: User;
  protected _slots:Map<string, NFTOnGameEC>;

  constructor(client:EOSClient) {
    super(client);
    this._name = NftPandaContract.NAME;
    this._contractName = NftPandaContract.CONTRACT_NAME;
    this._tokenContract = NftPandaContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'BAM';

    this._user = {
      allsum: 0, balance: 0, inrent: 0, max_slots: 0, maxrank: 0, refnum: 0, slot_counts: [], username: ''
    }

    this._slots = new Map();

  }

  public loadAssets = async () => {


    await this.loadBalances();

    await this.getPlayerSlots();
    
    await this.getRunningAdventure();

    this.updated();

    return this;
  }

  public reloadAssets = async() => {

    await this.loadBalances();

    await this.getPlayerSlots();

    await this.getRunningAdventure();
    
    this.updated();
    
  }  

  public async getPlayerSlots() {
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: NftPandaContract.CONTRACT_NAME,
        scope: NftPandaContract.CONTRACT_NAME,
        table: 'usersnew',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 10,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._user = rows[0];
    }
    catch (e) {
      console.error(e);
    }
  }

  public async getRunningAdventure() {
    const client = this._client;
     try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: NftPandaContract.CONTRACT_NAME,
        scope: NftPandaContract.CONTRACT_NAME,
        table: 'nftsongamec',
        key_type: "i64",
        index_position: 2,
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: -1,
        reverse: true,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        const slot: NFTOnGameEC = row;
        this._slots.set(slot.asset_id, slot);
      })
    }
    catch (e) {
      console.error(e);
    }
  }

  public getTimeleft(slot:NFTOnGameEC): number {
    const t = slot.timer - new Date().getTime() / 1000;
    return Math.round(t);
  }

  public async autoActions(): Promise<TransactionAction[]> {
    
    const client = this._client;

    await this.reloadAssets();
    
    const actions: TransactionAction[] = [];

    
    const slots = Array.from(this._slots.values());
    slots.forEach((slot)=> {
      const timeLeft = this.getTimeleft(slot);
      const signValue = cryptoRandomString({length: 14, type: 'numeric'});
      if (timeLeft < 0) {
        console.log(`Start adventure for pandan ${slot.asset_id}`);
        actions.push(
          this.createGameActions(
            NftPandaContract.NAME, 
            client.userAccount, 
            'printrand', 
            {
              assoc_id: slot.asset_id,
              username: client.userAccount,
              signing_value: signValue,
            }
          )
        );
      }
    })

    return actions;
  }

  public get slots() {
    return this._slots;
  }


}


/*


nftpandawofg - login
partner: 
signing_value: 79267724742015
username: qwnho.wam

nftpandawofg - addtoslot
asset_id: 1099569700041
number_slot: 0
username: qwnho.wam


Adventure
nftpandawofg - printrand
assoc_id: 1099569700041
signing_value: 60798765969097
username: qwnho.wam

const rewardTrans = {
    actions: [{
        account: 'nftpandawofg',
        name: 'printrand',
        authorization: [{
          actor: '', // use account that was logged in
          permission: 'active',
        }],
        data: {
            username: '',
            assoc_id: '',
            signing_value: ''
        },
    }],
}
    async sendPandaToAdventures(assoc_id, slotNumber) {
        const number = cryptoRandomString({length: 14, type: 'numeric'});
        const userName = this.props.ual.activeUser.accountName;
        const activeUser = this.props.ual.activeUser;

        var loadingSlot = document.getElementsByTagName("body")[0];
        loadingSlot.classList.add("loading");

        rewardTrans.actions[0].authorization[0].actor = userName
        rewardTrans.actions[0].data.username = userName
        rewardTrans.actions[0].data.assoc_id = assoc_id
        rewardTrans.actions[0].data.signing_value = number;


if(localStorage.getItem('signing_value') == null) {
  const number = cryptoRandomString({length: 14, type: 'numeric'});
  localStorage.setItem('signing_value', number); 
}
https://www.npmjs.com/package/crypto-random-string

*/