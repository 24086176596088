import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import FarmersWorldContract, { Member } from "../model/FarmersWorldcontract";

// export const CartMenuIcon = observer(({className, alt, selected}: MenuIconProps): React.ReactElement => {


interface GameProps {
    game: FarmersWorldContract
}

interface AssetProps {
    asset: Member
}

const MembersView = observer(({game}:GameProps): React.ReactElement => {
    
    const [lastRefresh, setLastRefresh] = useState(new Date());

    useEffect(() => {
        const ms = 5 * 1000;
        const id = setInterval(()=>{
            setLastRefresh(new Date());
        }, ms);
        return () => clearInterval(id);
    }, []);

    const handleClaimMember = async (asset: Member) => {
        console.log('handleClaimMember');
        //await game.claimTool(UserStore.getInstance(), asset.asset_id);
    }

    const TableHeaderRow = () => {
        return <tr><th>id</th><th>harvest</th></tr>;
    }

    const getTimeLeft = (tool: Member) => { 
        const t = tool.next_availability - Date.now() / 1000;        
        return Math.round(t);
    }


    const TableRow = ({data}:any) => {
        const HarvestBtn = ({asset}:AssetProps ) => {
            return (
                <button onClick={() => handleClaimMember(asset)}>claim</button>
            )
        }
        return data.map((data:Member) =>
            <tr key={data.asset_id}>
                <td>{data.config?.name}</td>
                <td>{ getTimeLeft(data) > 0 
                ? getTimeLeft(data)  
                : <div><HarvestBtn asset={data}></HarvestBtn></div>}</td>
            </tr>
        );
    }

    const Table = ({data}:any) => {
        console.log(data);
        return (
            <table>
            <TableHeaderRow />
            <TableRow data={data} />
            </table>
        );
    }

    return <div>
        <h1>Members Cards {lastRefresh.toLocaleString() }</h1>
        <Table data={game.mbs}></Table>
    </div>;
});

export default MembersView;

