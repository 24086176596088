import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import GameContract, { HarvestAsset, TransactionAction } from "./contract/GameContract";
import EOSClient from "./RPCClient";

export default class GameStore {

    private _client: EOSClient;
    private _games: Map<string, GameContract>;
    private _actions: TransactionAction[];
    private _actionRunning: boolean;
    private _retryCount: number;

    
    constructor(client:EOSClient) {

        this._client = client;

        this._actions = [];
        this._actionRunning = false;        
        this._retryCount = 0;

        this._games = new Map();

        makeObservable(this, {
            _games: observable,
        } as AnnotationsMap<this, string>);
    }

    public addGame(game:GameContract) {
        runInAction(()=> {
            this._games.set(game.name, game);
        })
    }

    public getGameByName(name:string): GameContract {
      if (this._games.has(name)) {
        return this._games.get(name)!;
      }
      return new GameContract(this._client);
    }

    public get games(): Map<string, GameContract> {
      return this._games;
    }

    public getGamesArray(): GameContract[] {
      return Array.from(this._games.values());
    }
  
    public getGame(name:string): GameContract {
        if (this._games.has(name)) {
            return this._games.get(name)!;
        }
        return new GameContract(this._client);
    }

    public async loadGameAssets() {
        for (let key of this._games.keys()) {
          const game = this._games.get(key);
          if (game && game.isSelectedForBot) {
            console.log(`Start loading asset for game ${key}`);
            await this._games.get(key)?.loadAssets();
          }
        }
    }

    public async getHarvestItems() {
        let items:HarvestAsset[] = [];
        for (let key of this._games.keys()) {
            const game = this._games.get(key);
            const list = await game?.listHarvest();
            // console.log(`game > ${game?.name}`, list);
            if (list) {
                items = items.concat(list);
            }
        }
        return items;
    }

    public async autoActions() {

      if (this._actionRunning) {
        console.warn(`Action is running (${this._retryCount})`);
        
        this._retryCount++;

        if (this._retryCount > 10) {
          this._retryCount = 0;
          const removedAction = this._actions.shift();
          console.error(`After 10 try must remove `, removedAction);
          this._actionRunning = false;
        }

        return;
      }

      if (this._actions.length === 0) {
        for (let key of this._games.keys()) {
          const game = this._games.get(key);
          if (game && game.isSelectedForBot) {
            console.log(`--- check for new actions on game ${game.name} ---`);
            const gameActions = await game.autoActions();
            if (gameActions) {
              console.log(`found ${gameActions.length} actions on game ${game.name} ---`);
              this._actions = this._actions.concat(gameActions);
            }
            game.updated();
            console.log(`-----------`);
          }
        }  
      }
      // si on a des actions précédentes ou on a trouvé de nouvelles actions 
      if (this._actions.length !== 0) {
        // si on trouves des actions le bot va lancer une action à chaque fois 
        console.log(`must play next action ${this._actions.length}`);
        const action = this._actions.shift();
        if (action) {
          console.log(`log will play`);
          console.log(action);
          try {
            this._actionRunning = true;
            this._retryCount = 0;
            await action.game.runTransaction(action);
            await action.game.finalizeAction(action);
            await action.game.updateAssets();
            action.game.updated();
            this._actionRunning = false;
          }
          catch (e) {
            console.error(e);
            console.warn('will replay the action')
            this._actions.unshift(action);
            this._actionRunning = false;
            await action.game.updateAssets();
            action.game.updated();
            // throw(e);
          }
        }
      }

    }
}