import React, { useEffect, useState } from "react";
import UserStore from "../../../model/UserStore";
import { observer } from "mobx-react-lite";
import FarmingTaleContract from "../model/FarmingTaleContract";
import AnimalsView from "./AnimalsView"
/*

    useEffect(() => {
        //todo: this code has no meaning
        //if (sideBarVisibility) {
        //   setSideBarVisibility(false);
        //}

        when(() => isAutoConnected()).then(async () => {
            const navLinks = await getLinkProps();
            if (navLinks.length > 0) {
                setMenuItems(navLinks);
            }
        });
    }, []);    
*/
// export const CartMenuIcon = observer(({className, alt, selected}: MenuIconProps): React.ReactElement => {
const FarmingTaleView = observer((): React.ReactElement => {
  const userStore = UserStore.getInstance();
  const [game, setGame] = useState(new FarmingTaleContract(userStore.client));

  useEffect(() => {
    if (userStore.isLogged()) {

      const updateGame = async () => { 
        const game = new FarmingTaleContract(userStore.client);
        await game.loadAssets();
        setGame(game);  
      }
      updateGame();

      
      /*
      setInterval(() => {
        console.log("calculate time left");
        getTimeLeft("1099571126880", 2 * 3600 + 10 * 60, "cow");
        getTimeLeft("1099571126884", 3 * 3600, "sheep");  
      }
      , 60000); // 10334
      */
    }
  }, [userStore]);

  return (
    <div>
      <h1>FarmingTale</h1>
      {userStore.isLogged() ? (
        <div>
          <AnimalsView game={game}></AnimalsView>
          <div>
            <button>Claim</button>
          </div>
        </div>
      ) : (
        "Must loggin"
      )}
    </div>
  );
});

export default FarmingTaleView;
