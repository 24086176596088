import AtomicAssetClient, { AtomicAsset, AtomicAssetParam } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { HarvestAsset, TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";
import { getRandomInt } from "../../../model/Tools";
/**
 * https://wax.bloks.io/account/rr.century
 * 
 * voir les parcours en cours
 * contract : rr.century
 * table:     railruns
 * scope:     qwnho.wam
 * 
 * train (key) run_start	run_complete	depart_station	arrive_station	boost
 * 
 * Les trains
 * contract : rr.century
 * table:     trains
 * scope:     qwnho.wam
 * train (key) locomotives conductors	load weight	locked last_run_time last_run_tx current_station previous_station	century	tampered
 * 
 * Les asserts utilisés 
 * contract : rr.century
 * table:     assets
 * scope:     qwnho.wam
 * asset_id (key) train
 * 
 * actions
 * 
 * programme un déplacement
 * https://wax.bloks.io/transaction/3b48f7772a97b3d4958374dc89d2ccff8fed3746f7bb5d6c35bc6a41fdde3b9d
 * 
 * récup à la fin du transport
 * https://wax.bloks.io/transaction/90749ff11fdc8664a0444051b1bd386b543212fd0f8dd7643082721c368ae5d8
 * 
 * Acheter du diesel 
 * https://wax.bloks.io/transaction/7b0f26f7b5b782bec1c77cd0f7433e41ad3507e9789133abece510f2b17f0a4f
 * 
 * 
 * toc.century
      from qwnho.wam
      to m.century
      toc.century - transfer
        from qwnho.wam    →   
        to m.century   
        1000 TOCIUM
        4|DIESEL|50000000|modern
 * 
 * atomicasset >  neftyblocksp
 *
 * GET DIESEL
 * code: "simpleassets"
* index_position: 1
* json: true
* key_type: ""
* limit: 1
* lower_bound: "100000018619367"
* reverse: false
* scope: "qwnho.wam"
* show_payer: false
* table: "accounts"
* upper_bound: "100000018619367"
* 
  GET TOCIUM
  code: "toc.century"
index_position: 1
json: true
key_type: ""
limit: 1
lower_bound: ""
reverse: false
scope: "qwnho.wam"
show_payer: false
table: "accounts"
upper_bound: ""


STATIONS
code: "rr.century"
index_position: 1
json: true
key_type: ""
limit: 400
lower_bound: ""
reverse: false
scope: "modern"
show_payer: false
table: "stations"
upper_bound: ""

 * 
 * Twin Garden -> 1099577692750
 * Sea Depot -> 1099577692745
 * 1099577692749
 * 1099577692769
 * 1099577692747
 * 1099577693396
 * 1099577692601
 * 1099577693396
 * 1099577692909
 * 1099577693396
 * 
 * boucle 1 
 * Sea Depot :          1099577692745
 * Sleeping landing :   1099577692749
 * Watcher park :        
 * Twin Gardens :       1099577692750
 * Sea Depot : 
 * 
 * boucle 2 
 * Sea Depot :          1099577692745
 * Sleeping landing :   1099577692749
 * Watcher park :        
 * Sleeping station :   
 * Winter Garden :      
 * Ember Station :      
 * Lagoon Landing :     
 * Twin Gardens :       1099577692750
 * Sea Depot :          1099577692745
 * 1099577692637
 * 
 * 
 * nom des stations
 * https://wax.api.atomicassets.io/atomicassets/v1/assets?page=1&limit=1000&collection_name=centurytrain&schema_name=station
 * 
 */

interface RailRun {
  train: string,
  run_start: number, 
  run_complete: number,
  depart_station: string,
  arrive_station: string,
	boost: number,
}

interface Train {
  train: string,
  last_run_time: number,
  last_run_tx: string,
  current_station: string,
  previous_station: string,
  century: string,
}

interface ConnectedStation {
  station_id: string,
  distance: number
}

interface TypeRate {
  type: string,
  distance: number
}

interface StationAsset extends AtomicAsset {
  mutable_data: {
    station_name: string,
  },
  immutable_data: {
    img: string,
    region: string,
    region_id: number  
  }
}

interface Station {
  station_id: string,
  connected_stations: ConnectedStation[], // { "station_id": "1099577687016", "distance": 19 
  type_rates: TypeRate[] // { "type": "ore", "multiplier": 100 },
  rarity: string,
  region_id: number,
  multiplier: number,
  config?: StationAsset
}


export default class CenturyTrainContract extends GameContract {
  static NAME = 'rr.century';
  static CONTRACT_NAME = 'rr.century';
  static COLLECTION_NAME = 'centurytrain';
  static TOKEN_CONTRACT = 'toc.century';

  private _railRuns: RailRun[] = [];
  private _trains: Train[] = [];
  private _stations:Record<string, Station> = {};

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmersWorldContract] constructor');

    this._name = CenturyTrainContract.NAME;
    this._railRuns = [];
    this._trains = [];
    this._stations = {};

    this._tokenContract = CenturyTrainContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'TOCIUM';
  }

  public loadAssets = async () => {

    this._stations = await this.loadStations();

    /*
    for (let key in this._stations) {
      const station = this._stations[key];
      // console.log(station.config?.mutable_data.station_name, station.station_id);
      const name = station.config?.mutable_data.station_name;
      const id = station.station_id; // Third Eclipse Arid Light First Brown Crown
      if (name!.indexOf('First') !== -1 || name!.indexOf('Brown') !== -1 || name!.indexOf('Crown') !== -1) {
        console.log(id, name);
      }
    }
    */

    // console.log(this._stations);
    // console.log(this._stations['1099577692637']);

    this._railRuns = await this.loadRailRuns();
    // console.log(this._railRuns);

    this._trains = await this.loadTrains();
    //console.log(this._trains);

    await this.loadBalances();
    // await this.loadAllAssets(wax);
    
    this.updated();

    return this;
  }

  public reloadAssets = async() => {
    // must reload stations if owner change !
    this._stations = await this.loadStations();
    // 
    this._railRuns = await this.loadRailRuns();
    // 
    this._trains = await this.loadTrains();
    // 
    await this.loadBalances();
    //
    this.updated();
  }  
 
  public async loadBalances(symbol:string='') {
    await super.loadBalances(symbol);
    // get DIESEL
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: 'simpleassets',
        scope: client.userAccount,
        table: 'accounts',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      // console.log(rows);
      const a = rows[0]['balance'].split(' ');
      this._tokens[a[1]] = {
        symbol: a[1],
        value: parseFloat(a[0])
      };
    }
    catch (e) {
      console.error(e);
    }
    // console.log(this._tokens);
  }

  private async loadTrains(): Promise<Train[]> {
    const client = this._client;
    const trains: Train[] = [];
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: CenturyTrainContract.NAME,
        scope: client.userAccount,
        table: 'trains',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        trains.push(row);
      });
    }
    catch (e) {
      console.error(e);
    }

    return trains;
  }

  private async loadStations(): Promise<Record<string, Station>> {
    const client = this._client;
    const stations: Record<string, Station> = {};
    try {

      const results = await client.rpc.get_table_rows({
        json: true,
        code: CenturyTrainContract.NAME,
        scope: 'modern',
        table: 'stations',
        limit: 400,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        stations[row['station_id']] = row;
      });

      // https://wax.api.atomicassets.io/atomicassets/v1/assets?page=1&limit=1000&collection_name=centurytrain&schema_name=station
      const atomicClient = new AtomicAssetClient();
      const params: AtomicAssetParam = {
        page: 1,
        limit: 500,
        collection_name: 'centurytrain',
        schema_name: 'station'
      }
      const assets = await atomicClient.loadAssets(params);

      assets.forEach((element:AtomicAsset) => {
        const asset: StationAsset = element as StationAsset;
        if (stations[asset.asset_id] !== undefined) {
          stations[asset.asset_id].config = asset;
        }
      });
    }
    catch (e) {
      console.error(e);
    }

    return stations;
  }

  private async loadRailRuns(): Promise<RailRun[]> {
    /*
    * contract : rr.century
    * table:     railruns
    * scope:     qwnho.wam    
    */
    const client = this._client;
    const railRuns: RailRun[] = [];
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: CenturyTrainContract.NAME,
        scope: client.userAccount,
        table: 'railruns',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        railRuns.push(row);
      });
    }
    catch (e) {
      console.error(e);
    }

    return railRuns;
  }

  public getStationById(id:string):Station {
    if (this._stations[id] !== undefined) {
      return this._stations[id];
    }
    return {
      station_id: 'nono',
      connected_stations: [], // { "station_id": "1099577687016", "distance": 19 
      type_rates: [], // { "type": "ore", "multiplier": 100 },
      rarity: '',
      region_id: 0,
      multiplier: 0,
    };
  }

  public get trains() {
    return this._trains;
  }

  public get railruns() {
    return this._railRuns;
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    const list: HarvestAsset[] = [];
    this._railRuns.forEach((railRun) => {
      list.push({
        id: railRun.train,
        label: railRun.train,
        timeLeft: 0,
        collectionName: CenturyTrainContract.COLLECTION_NAME,
      })
    })
    return list;
  }

  public getTimeLeft(railrun:RailRun) {
    return Math.round(railrun.run_complete - Date.now() / 1000);
  }

  public async autoActions(): Promise<TransactionAction[]> {
    
    const client = this._client;
    await this.reloadAssets();
    
    const actions: TransactionAction[] = [];


    // check if one railrun is over 
    this._railRuns.forEach((railrun) => {
      const timeLeft = this.getTimeLeft(railrun);
      console.log(`[${railrun.train}] going to ${railrun.arrive_station} tl: ${timeLeft} next: ${this.getNextStation(railrun.arrive_station)}`);
      const station = this._stations[railrun.arrive_station];
      if (timeLeft < 0) {
        console.log('will claim the resources and select next destination !');
        actions.push(
          this.createGameActions(
            CenturyTrainContract.NAME, 
            client.userAccount, 
            'claim', 
            {
              owner: station.config?.owner,
              railroader: client.userAccount,
              train: railrun.train,
            }
          )
        );
      }
    });

    // check if some train not running
    const ide:Train[] = this.findNotRunningTain();
    if (ide.length !== 0) {
      ide.forEach((train) => {
        const nextStation = this.getNextStation(train.current_station);
        console.log(`Program next move for ${train.train} ${nextStation}`);
        actions.push(this.getTransportAction(train.train, nextStation));
      })
    }
    return actions;
  }

  public createBuyDieselAction():TransactionAction {
    /*
    * toc.century
    from qwnho.wam
    to m.century
    toc.century - transfer
      from qwnho.wam    →   
      to m.century   
      1000 TOCIUM
      4|DIESEL|50000000|modern
    */
    return this.createGameActions(
        CenturyTrainContract.TOKEN_CONTRACT,
        this._client.userAccount,
        'transfer',
        {
          'from': this._client.userAccount,
          'to': 'm.century',
          'quantity': '1000.0000 TOCIUM',
          'memo': '4|DIESEL|50000000|modern'
        }
      )
  } 

  private findNotRunningTain(): Train[] {
    
    const runningTrains: string[] = [];
    this._railRuns.forEach((railrun) => {
      runningTrains.push(railrun.train);
    });

    const notRunning: Train[] = [];
    this._trains.forEach((train)=> {
      if (runningTrains.indexOf(train.train) === -1) {
        console.log(`This train ${train.train} is not running !`);
        notRunning.push(train);
      }
    });

    return notRunning;
  }

  private getTransportAction(train:string, station:string):TransactionAction {
    const client = this._client;
    return this.createGameActions(
      CenturyTrainContract.NAME, 
      client.userAccount, 
      'transport', 
      {
        arriving: station,
        railroader: client.userAccount,
        train: train,
      }
    )
  }

/*
Eternal Garden 		1099577692753 -> 
Pinacle depot 			1099577692597 -> 
Vendetta Landing  		1099577693475 -> 
Seabreeze Park 		1099577691891
Blood Park ->			1099577691907
Third station 			1099577688101 -> 
Eclipse Station -> 		1099577688864
Arid Landing -> 		1099577688795
Light garden -> 		1099577689349
First station			1099577689185
Brown keep park -> 	1099577689621
Pinnacle Landing 		1099577688250
Crown Park 			1099577688062
Eternal Landing 		1099577688270
*/
  private _trips:Record<string, string[]> = {
    'rubijn1': [
      '1099577688250', // Pinnacle Landing 
      '1099577688062', // Crown Park
      '1099577688270', // Eternal Landing
    ],
    'runciter42': [
      '1099577688270', // Eternal Landing		
      '1099577688250', // Pinnacle Landing 		
      '1099577688062', // Crown Park			
    ]
  }



  private getNextStation(stationId:string) {
    const maxDistance = 60; // TODO: get max distance from loco
    if (this._stations[stationId] !== undefined) {
      const station = this._stations[stationId];
      const trainName = this._trains[0].train;
      if (trainName === 'rubijn1' || trainName === 'runciter42') {
        const navigate = this._trips[trainName];
        let index = navigate.indexOf(stationId);
        if (index !== -1) {
          index++;
          if (index === navigate.length) {
            index = 0
          }
          console.warn(`Special trip for ${trainName} ${stationId} -> ${navigate[index]}`);
          return navigate[index];  
        }
      }
      

      // get station in same region
      const authorizedDestinations:string[] = [];
      station.connected_stations.forEach((s)=> {
        const arrival = this._stations[s.station_id];
        // 1099577688068
        if (arrival && arrival.region_id === station.region_id && s.distance <= maxDistance) {
          authorizedDestinations.push(arrival.station_id);          
        } else if (arrival) {
          // console.warn(`could not leave region ${arrival.station_id}`);
        }
      })
      // console.log(authorizedDestinations);
      const numConnected = authorizedDestinations.length;
      const index = getRandomInt(0,numConnected);
      return authorizedDestinations[index];
    }
    return '';
  }




}