import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../model/UserStore";

const Login = observer((): React.ReactElement => {

  const [userAccount, setUserAccount] = useState('qwnho.wam');
  // qwnho.wam hboiy.wam
  const handleLogin = async () => {
    await UserStore.getInstance().login();
  };
  /*
  const handleChangeLogin = (e:any) => {
    setUserAccount(e.target.value);
  }

  const handleSimpleLogin = () => {
    UserStore.getInstance().login(userAccount);
  }
  */
  return (
    <div className="container login">
      <div>
        <p>Welcome to Spice a bot manager for WAX Games</p>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
});

export default Login;

/*
      <div className="login-simulator">
        <input type="text" value={ userAccount } onChange={ handleChangeLogin }></input>
        <button onClick={handleSimpleLogin}>Send</button>
      </div>
*/