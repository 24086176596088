import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import GameContract, { TransactionAction } from "./contract/GameContract";
import UserStore from "./UserStore";

export default class GameBot {

  private _mainLoopId: NodeJS.Timeout|undefined;
  private _lastUpdate: Date;
  private _loopTimeMs:number;
  private _isReady:boolean;
  private _isRunning:boolean;
  private _game:GameContract;
  private _actions: TransactionAction[];
  private _actionRunning: boolean;
  private _retryCount: number;

  private _errorActions:string[];
  private _isCPULock:boolean;

  constructor(game:GameContract) {
    
    this._lastUpdate = new Date();
    this._loopTimeMs = 15 * 1000;
    this._isReady = false;
    this._isRunning = false;
    this._game = game;
    this._actions = [];
    this._actionRunning = false;
    this._retryCount = 0;

    this._isCPULock = false;
    this._errorActions = [];

    makeObservable(this, {
      _lastUpdate: observable,
      _isReady: observable,
      _isRunning: observable,
    } as AnnotationsMap<this, string>);

  }

  public get game() {
    return this._game;
  }

  public async init() {
    this.stopWatch();
    this._actions = [];
    this._actionRunning = false;
    this._retryCount = 0;
    await this._game.loadAssets();
    runInAction(()=> {
      this._isReady = true;
    })
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  public get isReady():boolean {
    return this._isReady;
  }

  public async startWatch() {
    if (this._mainLoopId) {
      clearInterval(this._mainLoopId);
    }
    this._mainLoopId = setInterval(() => this.mainLoop(), this._loopTimeMs);
    await this._game.autoActions();
    runInAction(() => {
      this._isRunning = true;
    })
  }

  protected async mainLoop() {
    console.log(`runing ${this._game.name} bot`);

    if (this._actionRunning) {
      console.warn(`Action is running (${this._retryCount})`);
      this._retryCount++;
      if (this._retryCount > 10) {
        this._retryCount = 0;
        const removedAction = this._actions.shift();
        console.error(`After 10 try must remove `, removedAction);
        this._actionRunning = false;
      }

    }    

    if (this._actions.length === 0) {
      if (this._game && this._game.isSelectedForBot) {
        console.log(`--- check for new actions on game ${this._game.name} ---`);
        const gameActions = await this._game.autoActions();
        if (gameActions) {
          console.log(`found ${gameActions.length} actions on game ${this._game.name} ---`);
          this._actions = this._actions.concat(gameActions);
        }
        await this._game.updateAssets();
        console.log(`-----------`);
      }
    }  

    // si on a des actions précédentes ou on a trouvé de nouvelles actions 
    if (this._actions.length !== 0) {
      // check if CPU is available 
      const pctCPU = UserStore.getInstance().getCPUUsagePercent();
      if (pctCPU && pctCPU > 0.98) {
        console.warn(`No CPU ailable ${pctCPU} skip this loop for game ${this._game.name}`);
        runInAction(async ()=> {
          this._lastUpdate = new Date();
        })        
        return;
      }
      // si on trouves des actions le bot va lancer une action à chaque fois 
      console.log(`must play next action ${this._actions.length}`);
      const action = this._actions.shift();
      if (action) {
        console.log(`log will play`);
        console.log(action);
        try {
          this._actionRunning = true;
          this._retryCount = 0;
          await this._game.runTransaction(action);
          await this._game.finalizeAction(action);
          this._actionRunning = false;
        }
        catch (e) {
          console.error(e);
          // Unknown action mine in contract stakeanimal1
          // Error: billed CPU time (379 us) is greater than the maximum billable CPU time for the transaction (0 us) 

          console.warn('will replay the action')
          this._actions.unshift(action);
          this._actionRunning = false;
        }
        await this._game.updateAssets();
      }
    }

    runInAction(async ()=> {
      this._lastUpdate = new Date();
    })
  }

  public stopWatch() {
    if (this._mainLoopId) {
      console.log(`stop ${this._game.name} bot`);
      clearInterval(this._mainLoopId);
      runInAction(async ()=> {
        this._isReady = false;
        this._isRunning = true;
      })
  
    }
  }

  public get lastUpdate(): Date {
    return this._lastUpdate;
  }
 
}