import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import Asset from "../../../model/atomic/Asset";
import GameContract, { HarvestAsset, TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";
import UserStore from "../../../model/UserStore";

/*
Contract 
* https://wax.bloks.io/account/farminggames

code: "farminggames"
index_position: 1
json: true
key_type: ""
limit: 1
lower_bound: "qwnho.wam"
reverse: false
scope: "farminggames"
show_payer: false
table: "resources"
table_key: ""
upper_bound: "qwnho.wam"


by food
farmingtoken
transfer

from: qwnho.wam
to : farminggames  
memo: food_refill
*/

export interface Resource {
  food: number,
  food_max: number,
  owner: string,
  silo_id: string,
  silo_template_id:string,
  water: number,
  water_max: number,
  water_template_id: string,
  water_tower_id: string,
}


export interface AnimalConf {
  template_id: string,
  label: string,
  resource_type: string,
  resource_count: string,
  sest_amount: string,
  cooldown: string,
  food: number,
};

export interface BuilingdConf {
  template_id: string,
  label: string,
  animal_templates: string[],
};

export interface Building {
  asset_id: string,
  owner: string,
  template_id: string,
  config?: BuilingdConf
}

export interface Animal {
  asset_id: string,
  building_asset_id: string,
  last_harvest: number, 
  owner: string,
  template_id: string
  config?: AnimalConf
}

export default class FarmingTaleContract extends GameContract {

  static NAME = 'farminggames';
  static CONTRACT_NAME = 'farminggames';  
  static COLLECTION_NAME = 'farmingtales' 
  static TOKEN_CONTRACT = 'farmingtoken'

  private _animals: Animal[] = [];
  private _animalsConf: Map<string, AnimalConf>;

  protected _builds: Building[] = [];
  protected _buildsConf: Map<string, BuilingdConf>;

  protected _all: Asset[] = [];
  protected _toClaim: number = 0;
  protected _tokenBalance: number = 0;
  protected _resource: Resource;

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmingTaleContract] constructor');
    this._name = FarmingTaleContract.NAME;
    this._contractName = FarmingTaleContract.NAME;
    this._tokenContract = FarmingTaleContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'SEST';

    this._animalsConf = new Map();
    this._buildsConf = new Map();

    makeObservable(this, {
      _toClaim: observable,
      _tokenBalance: observable,
    } as AnnotationsMap<this, string>);

    this._resource = {
      water_max: 100,
      water: 0,
      food: 0,
      food_max: 100,
      water_template_id: '',
      water_tower_id: '',
      silo_id: '',
      silo_template_id: '',
      owner: ''
    }

  }

  public loadAssets = async () => {
    //
    this._animalsConf = await this.loadConfAnimals();
    // 
    this._animals = await this.loadAnimals();
    // console.log(`Found ${this._animals.length} animals for ${store.wax.userAccount}`);

    this._buildsConf = await this.loadConfBuilds();
    //
    this._builds = await this.loadBuilds();
    // console.log(`Found ${this._builds.length} builds for ${store.wax.userAccount}`);

    await this.loadBalances();

    await this.loadResources();

    await this.getBalanceToClaim();

    this._all = UserStore.getInstance().getAssetsByCollection(FarmingTaleContract.COLLECTION_NAME);
    // console.log(this._all);

    this.updated();

    return this;
  }

  public get resource(): Resource {
    return this._resource;
  }

  public getTimeLeft(animal:Animal): number {
      const t = Date.now() / 1000 - animal.last_harvest;
      if (animal.config) {
          return Math.round(parseInt(animal.config.cooldown) - t);
      }
      return 5000000;
  }

  public reloadAssets = async() => {
    this._animals = await this.loadAnimals();
    this._builds = await this.loadBuilds();
    await this.getBalanceToClaim();
    await this.loadBalances();
    await this.loadResources();
    this.updated();
  }

  protected async getBalanceToClaim() {
    const client = this._client;
    const results = await client.rpc.get_table_rows({
      json: true,
      code: FarmingTaleContract.NAME,
      scope: 'farminggames',
      table: 'claim',
      lower_bound: client.userAccount,
      limit: 1,
      reverse: false,
      show_payer: false
    });
    const data = results['rows'][0];
    runInAction(()=>{
      this._toClaim = parseInt(data['to_claim']) / 100000;
    })
  }

  protected loadResources = async (): Promise<any> => {
    try {
      const results = await this._client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'resources',
        lower_bound: this._client.userAccount,
        upper_bound: this._client.userAccount,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._resource = rows[0];
      console.log(this._resource);
    }
    catch (e) {
      console.error(e);
    }
  }

  protected loadBuilds = async (): Promise<Building[]> => {
    const client = this._client;
    const builds: Building[] = [];
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'building',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner'];
        if (owner === client.userAccount) {
          /*
          const asset = new FarmingTaleAsset(row['asset_id']);
          asset.owner = row['owner'];
          asset.templateId = row['template_id'];
          */
          const asset:Building = {
            asset_id: row['asset_id'],
            owner: row['owner'],
            template_id: row['template_id'],
          } 
          if (this._buildsConf.has(asset.template_id)) {
            asset.config = this._buildsConf.get(asset.template_id);
          }
          builds.push(asset);
        }
      });
    }
    catch (e) {
      console.error(e);
    }
    return builds;
  }

  public loadAnimals = async (): Promise<Animal[]> => {
    const animals: Animal[] = [];
    try {
      const client = this._client;
      const results = await client.rpc.get_table_rows({
        json: true,
        code: 'farminggames',
        scope: 'farminggames',
        table: 'animal',
        index_position: 2,
        key_type: 'name',
        lower_bound: client.userAccount,
        limit: 50,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        // console.log(row);
        const owner: string = row['owner']; 
        if (owner === client.userAccount) {
          const asset: Animal = {
            asset_id: row['asset_id'],
            owner: row['owner'],
            template_id: row['template_id'],
            building_asset_id: row['building_asset_id'],
            last_harvest: row['last_harvest'],
          }
          if (this._animalsConf.has(asset.template_id)) {
            asset.config = this._animalsConf.get(asset.template_id);
          }
          animals.push(asset);
        }
      });
  
    }
    catch (e) {
      console.error(e);

    }
    return animals;
  }

  protected getAnimalConfig(template_id: string) {
    this._animalsConf.forEach((a) => {
      if (a.template_id === template_id) {

      }
    })
  }

  public loadConfAnimals = async (): Promise<Map<string, AnimalConf>> => {
    const client = this._client;
    const configurations: Map<string, AnimalConf> = new Map<string, AnimalConf>();
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'confanimal',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }
    return configurations;
  }

  public async loadConfBuilds() {
    const client = this._client;
    const configurations: Map<string, BuilingdConf> = new Map<string, BuilingdConf>();
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: 'farminggames',
        table: 'confbuilding',
        limit: 100,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      rows.forEach((row) => {
        configurations.set(row['template_id'], row);
      });
    }
    catch (e) {
      console.error(e);
    }
    return configurations;
  }

  public get animals(): Animal[] {
    return this._animals;
  }

  public updateLastHarvest = async (client: EOSClient, animal: Animal) => {
    try {
      const assets = await client.rpc.get_table_rows({
        json: true,
        code: FarmingTaleContract.NAME,
        scope: "farminggames",
        table: "animal",
        lower_bound: animal.asset_id,
        limit: 1,
        reverse: false,
        show_payer: false,
      });
      const resultAnimal = assets.rows[0];
      animal.last_harvest = resultAnimal['last_harvest'];
    }
    catch (e) {
      console.error(e);
    }
  }

  public async listHarvest(): Promise<HarvestAsset[]> {
    const list: HarvestAsset[] = [];
    this._animals.forEach((animal) => {
      list.push({
        id: animal.asset_id,
        label: animal.config?.label!,
        timeLeft: 0,
        collectionName: FarmingTaleContract.COLLECTION_NAME,
      })
    })
    return list;
  }

  public createByFoodAction() {
    /*
    farmingtoken
    transfer
    from: qwnho.wam
    to : farminggames  
    memo: food_refill
    */
    const action = this.createGameActions(
      FarmingTaleContract.TOKEN_CONTRACT,
      this._client.userAccount,
      "transfer",
      {
        from: this._client.userAccount,
        to: FarmingTaleContract.CONTRACT_NAME,
        memo: 'food_refill',
        quantity: '20.0000 SEST'
      }          
    );
    return action;
  }

  public async autoActions(): Promise<TransactionAction[]> {
    
    await this.reloadAssets();

    // check all animals 
    const actions: any[] = [];
    const client = this._client;
    this._animals.forEach((animal) => {
      const timeLeft = this.getTimeLeft(animal);
      console.log(`animal ${animal.config?.label} timeLeft: ${timeLeft}`);

      /*
      if (this._resource.food < 20) {
        actions.push(this.createByFoodAction());
      }
      */

      if (timeLeft < 0) {
        // check if enough food
        const food = animal.config?.food;
        // console.warn(this._resource.food);
        if (food && food > this._resource.food) {
          console.warn('Must buy some food before harvest');
          actions.push(this.createByFoodAction());
        }
        else {
          actions.push(this.createGameActions(
            FarmingTaleContract.NAME,
            client.userAccount,
            "harvestanim",
            {
              account: client.userAccount,
              asset_id: animal.asset_id,
            }          
          ));  
        }
      }
    });

    if (this._toClaim !== 0) {
      actions.push(this.createGameActions(
        FarmingTaleContract.NAME,
        client.userAccount,
        "claim",
        {
          account: client.userAccount,
        }          
      ));
    }

    return actions;
    /*
    if (actions.length !== 0) {
      console.log(`Some actions need to be done ! ${JSON.stringify(actions)}`);
    }
    */
  }

  public get builds() {
    return this._builds;
  }

  public getAnimalsForBuild(build:Building) {
    const animals:Animal[] = [];
    this._animals.forEach((animal)=> {
      if (animal.building_asset_id === build.asset_id) {
        animals.push(animal);
      }
    })
    return animals; 
  }

}

