
import { observable, makeObservable, AnnotationsMap, runInAction } from "mobx";
import Asset from './atomic/Asset';
import AtomicAssetContract from './atomic/AtomicAssetContract';
import FarmingTaleContract from '../games/farmingtales/model/FarmingTaleContract';
import GameStore from './GameStore';
import GameContract from './contract/GameContract';
import FarmersWorldContract from '../games/farmersworld/model/FarmersWorldcontract';
import CenturyTrainContract from '../games/centurytrain/model/CenturyTrainContract';
import AlienWorldsContract from '../games/alienworlds/model/AlienWorldsContract';
import NftPandaContract from '../games/nftpandawofg/model/NftPandaContract';
import EOSClient, { WaxClient } from './RPCClient';
import Bot from "./Bot";
import { GetAccountResult } from 'eosjs/dist/eosjs-rpc-interfaces';
import AnimalWorldContract from "../games/animalworld/model/AnimalWorldContract";
import XpansionContract from "../games/xpansion/model/XpansionContract";
import GameBotStore from "./GameBotStore";
import GameBot from "./GameBot";

const SELECTED_GAMES = 'selected_games';

export default class UserStore {
  private _client: EOSClient;
  private _logged: boolean = false;
  private _authorized: boolean = false;
  private _assets: Asset[] = [];
  private _gameStore: GameStore;
  private _botStore:GameBotStore;
  private _selectedGames: Record<string, boolean>;
  private _bots:Bot[];
  private _account: GetAccountResult|undefined;
  private _mainLoopId: NodeJS.Timeout|undefined;

  static _instance: UserStore | undefined;


  public static getInstance(): UserStore {
    if (UserStore._instance === undefined) {
      UserStore._instance = new UserStore();
    }
    return UserStore._instance;
  }

  public constructor() {
    // console.log('UserConsructor');

    this._client = new WaxClient();
    // console.log('Start wax engine');

    this._bots = [];
    this._bots.push(new Bot());

    this._selectedGames = {};
    if (localStorage.getItem(SELECTED_GAMES)) {
      this._selectedGames = JSON.parse(localStorage.getItem(SELECTED_GAMES)!);
    }
    
    this._gameStore = new GameStore(this._client);
    const games = [
      new FarmersWorldContract(this._client), 
      new FarmingTaleContract(this._client), 
      new CenturyTrainContract(this._client),
      new AlienWorldsContract(this._client),
      new NftPandaContract(this._client),
      new AnimalWorldContract(this._client),
      new XpansionContract(this._client),
    ];

    this._botStore = new GameBotStore(this._client);
    
    games.forEach((game) => {

      if (this._selectedGames[game.name] !== undefined) {
        const selected = this._selectedGames[game.name];
        game.selectedForBot = selected;
      }
      this._gameStore.addGame(game);
      const b = new GameBot(game);
      this._botStore.addBot(b);
    })
   

    makeObservable(this, {
      _client: observable,
      _logged: observable,
      _authorized: observable,
      _assets: observable,
      _account: observable
    } as AnnotationsMap<this, string>);

  }

  public get gameStore(): GameStore {
    return this._gameStore;
  }

  public get botStore(): GameBotStore {
    return this._botStore;
  }

  public async startWatch() {
    console.log('-------- Start Watch -------------');
    if (this._mainLoopId) {
      this.stopWatch();
    }
    this._mainLoopId = setInterval(async ()=> {
      // console.log(`reload ${this.client.userAccount} account`);
      try {
        await this.loadAccount();
      }
      catch (e) {
        console.error(e);
      }
    }, 15* 1000)
  }

  public async stopWatch() {
    if (this._mainLoopId) {
      clearInterval(this._mainLoopId);
    }
  }

  public async startSelectedBots() {
    await this._botStore.startSelectedBots();
  }

  public async stopSelectedBots() {
    await this._botStore.stopSelectedBots();
  }

  public get defaultBot(): Bot {
    return this._bots[0];
  }

  public get userAccount(): string {
    if (this.isLogged()) {
      return this._client.userAccount;
    }
    return '';
  }

  public get client(): EOSClient {
    return this._client;
  }

  public isLogged() {
    return this._logged && this._authorized;
  }

  public async logout() {
    console.log('logout');
    runInAction(() => {
      this._logged = false;
    })  
  }

  public async login(account:string = '') {
    try {
      if (account === '') {
        await this._client.login();
        const isAuthorized = await this.validateUser(this._client);
        if (!isAuthorized) {
          console.error(`${this._client.userAccount} is not authorized`);
        }
        await this.loadAssets();
        await this.loadAccount();
        this.startWatch();
        runInAction(() => {
          this._logged = true;
          this._authorized = isAuthorized;
        })  
      }
      else {
        console.log(`Use simulation login ${account}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  public switchSelected(game: GameContract, selected: boolean) {
    this._selectedGames[game.name] = selected;
    game.selectedForBot = selected;
    localStorage.setItem(SELECTED_GAMES, JSON.stringify(this._selectedGames));
  }

  protected async validateUser(client: EOSClient) {
    const authorizedAccounts = ['hboiy.wam', 'qwnho.wam'];    
    if (authorizedAccounts.indexOf(client.userAccount) !== -1) return true;
    return false;
  }

  protected async loadAssets() {
    const contract = new AtomicAssetContract(this._client);
    this._assets = await contract.loadAssets();
  }

  protected async loadAccount() {
    try {
      const account  = await this._client.rpc.get_account(this._client.userAccount);
      runInAction(()=> {
        this._account = account;
      })
    }
    catch (e) {
      console.error(e);
    }
    return this.account;
  }

  public get account() {
    return this._account;
  }  

  public initGame(game: FarmingTaleContract) {
    game.loadAssets();
  }

  public getAssetsByCollection(name: string): Asset[] {
    const results: Asset[] = [];
    this._assets.forEach((a) => {
      if (a.colectionName === name) results.push(a);
    })
    return results;
  }

  public async autoActions() {
    return this._gameStore.autoActions();
  }

  public getCPUUsagePercent() {
    if (this._account) {
      const used = this.account?.cpu_limit.used!;
      const max = this.account?.cpu_limit.max!;
      return used / max;
    }
  }

  public getPctNET() {
    if (this._account) {
      const used = this.account?.net_limit.used!;
      const max = this.account?.net_limit.max!;
      return used / max;
    }
  }

}

