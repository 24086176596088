import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";

interface TreeInventory {
  key: string, 
  value: number
}

interface UserData {
  inventory?: TreeInventory[],
  last_claim: number,
  pool: string,
  unclaimed: string,
}

interface User {
  account: string,
  data?: UserData[],
}

/**
 * https://wax.bloks.io/account/stakeanimal1
 * 
 * qwnho.wam
 * 1099569700041
 * 
 */
export default class AnimalWorldContract extends GameContract {

  static NAME = 'stakeanimal1';
  static CONTRACT_NAME = 'stakeanimal1';
  static COLLECTION_NAME = 'animalworld1';
  static TOKEN_CONTRACT = 'tokenanimal1';

  protected _coolDownTimeInSeconds = 24 * 3600;
  protected _user:User;

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmersWorldContract] constructor');

    this._name = AnimalWorldContract.NAME;
    this._tokenContract = AnimalWorldContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'AWC';

    this._user = {
      account: '',
    }

  }

  public get user() {
    return this._user;
  }

  public loadAssets = async (): Promise<GameContract> => {
    await this.loadUser();
    super.loadAssets();
    return this;
  }

  public updateAssets = async():Promise<GameContract> => {
    await this.loadUser();
    super.updateAssets();
    return this;
  }  

  protected loadUser = async () => {
    // qwnho.wam
    const client = this._client;
    try {
      const results = await client.rpc.get_table_rows({
        json: true,
        code: AnimalWorldContract.CONTRACT_NAME,
        scope: AnimalWorldContract.CONTRACT_NAME,
        table: 'user',
        lower_bound: client.userAccount,
        upper_bound: client.userAccount,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      this._user = rows[0];
    }
    catch (e) {
      console.error(e);
    }    
  }

  public getTimeLeft(time:number) {
    const now = new Date().getTime() / 1000;
    const dt = this._coolDownTimeInSeconds - Math.round(now-time);
    return dt;
  }

  public async autoActions(): Promise<TransactionAction[]> {
    await this.updateAssets();
    const client = this._client;
    const actions: TransactionAction[] = [];
    const data = this._user.data!;
    if (data) {
      const timeLeft = this.getTimeLeft(data[0].last_claim);  
      if (timeLeft < 0) {
        actions.push(this.createGameActions(
          AnimalWorldContract.NAME,
          client.userAccount,
          "claim", // claim
          {
            _user: client.userAccount,
          }          
        ));  
      }
    }

    return actions;
  }


}
