
export function getRandomInt(min:number, max:number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function timeHourToString(timeInHour:number): string {
  const info = timeHourDecompose(timeInHour);
  return String(info[0]).padStart(2,'0') + ':' + String(info[1]).padStart(2,'0') + ':' + String(info[2]).padStart(2,'0');
}

export function timeHourDecompose(timeInHour:number): number[] {
  const hours = Math.floor(timeInHour / 3600)    
  const mins = Math.floor((timeInHour - hours * 3600) / 60);
  const secs = timeInHour - hours * 3600 - mins * 60;
  return [hours, mins, secs];
}
