import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import GameContract from "../model/contract/GameContract";
import UserStore from "../model/UserStore";

interface GameCardProps {
  game: GameContract;
}


const GameCard = ({game}:GameCardProps): React.ReactElement => {
  const [checked, setChecked] = React.useState(game.isSelectedForBot);
  const navigate = useNavigate();
  
  const handleConfig = () => {
    navigate(`/config/${game.name}`);
  }

  const handleChangeStatus = () => {
    const next = !checked;
    UserStore.getInstance().switchSelected(game, next);
    setChecked(next)
  }

  return (
    <div className="game-card">
      <h2>{ game.name }</h2>
      <label className="switch">
      <input type="checkbox" defaultChecked={checked} onChange={ handleChangeStatus }></input>
        <span className="slider round"></span>
      </label>
      <button className="btn-config" onClick={handleConfig}><i className="fas fa-cog"></i></button>
    </div>
  )
};


const Home = observer((): React.ReactElement => { 
    const userStore = UserStore.getInstance();
    
    
    const games = userStore.gameStore.getGamesArray().map((game) => {
      return (
        <GameCard key={game.name} game={game}></GameCard>
      )
    })
    
    const pctCPU = Math.floor(userStore.getCPUUsagePercent()!*10000)/100;
    const pctNET = Math.floor(userStore.getPctNET()!*10000)/100;

    return <div className="container game-selection">
      <h1>Select games</h1>
        
        { userStore.isLogged() ?         
          <div>{userStore.account?.account_name} CPU: {pctCPU }% NET: { pctNET }%</div>
          : <div></div>
        }

        { games }

        <ul>
            <li><Link to="/bot">Bot</Link></li>
            <li><Link to="/ft">Farming Tale</Link></li>
            <li><Link to="/fw">Farmers World</Link></li>
        </ul>
    </div>

});

export default Home;
