import React from "react";
import { GameProps } from "../../../components/GameView";
import CenturyTrainContract from "../model/CenturyTrainContract";
import { timeHourToString } from "../../../model/Tools";

const CTBotView: React.FunctionComponent<GameProps> = props => {
  const ct = props.game as CenturyTrainContract;

  const handleBuyDiesel = async () => {
    const action = ct.createBuyDieselAction();
    await action.game.runTransaction(action);
  }

  const railRunsView = ct.railruns.map((railrun) => {
    const timeLeft = ct.getTimeLeft(railrun);
    const fromStation = ct.getStationById(railrun.depart_station);
    const toStation = ct.getStationById(railrun.arrive_station);
    return (
          <tr key={railrun.train}>
            <td>{ railrun.train }</td>
            <td>{ fromStation.config?.mutable_data.station_name +" -> "}</td>
            <td>{ toStation.config?.mutable_data.station_name +' ('+toStation.config?.owner+') '}</td>
            <td>{ timeHourToString(timeLeft) }</td>
          </tr>
    )
  });
  return (
    <div>      
      <table>
        <tbody>
          { railRunsView }
        </tbody>
      </table>
      <button onClick={handleBuyDiesel}>Buy Diesel</button>
    </div>
  )
}

export default CTBotView;