import React from "react";
import { GameProps } from "../../../components/GameView";
import FarmingTaleContract from "../model/FarmingTaleContract";
import { timeHourToString } from "../../../model/Tools";

const FTBotView: React.FunctionComponent<GameProps> = props => {
  const ft = props.game as FarmingTaleContract;
  
  const handleBuyFood = async () => {
    const action = ft.createByFoodAction();
    await action.game.runTransaction(action);
  }

  const builds = ft.builds.map((build)=> {
    const animals = ft.getAnimalsForBuild(build);
    
    const animalsView = animals.map((a)=> {
      const timeLeft = ft.getTimeLeft(a);
      return (
        <tr key={a.asset_id}>
          <td>{a.config!.label} eat : {a.config?.food} and give {parseInt(a.config?.sest_amount!)/10000} SEST</td>
          <td>{timeHourToString(timeLeft)}</td>
        </tr>
      )
    });

    if (animals.length === 0) {
      return <div key={build.asset_id}></div>
    }

    return (
      <table key={build.asset_id+'-table'}>
        <tbody>      
          <tr key={build.asset_id+'-build'}>
            <td colSpan={2}>{ build.config?.label }</td>
          </tr>        
          { animalsView }
        </tbody>
      </table>
    )
  });


  const contract = props.game as FarmingTaleContract;
  return (
    <div className={props.game.name}>
      <div className="resources">Food : { contract.resource.food + ' / ' + contract.resource.food_max } Water: { contract.resource.water + ' / ' + contract.resource.water_max } </div>
      { builds }
      <button onClick={handleBuyFood}>Buy Food</button>
    </div>
  )
}

export default FTBotView;