import React from "react";
import { GameProps } from "../../../components/GameView";
import { timeHourToString } from "../../../model/Tools";
import AlienWorldsContract from "../model/AlienWorldsContract";

const AWBotView: React.FunctionComponent<GameProps> = props => {
  const aw = props.game as AlienWorldsContract;
  return (
    <div>      
      Alien Worlds { timeHourToString(aw.getTimeLeft(aw.miner.last_mine)) }
    </div>
  )
}

export default AWBotView;