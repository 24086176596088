import React from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../model/UserStore";
import { Link } from "react-router-dom";


const Navigation = observer((): React.ReactElement => { 
    const userStore = UserStore.getInstance();

    const LoginBtn = (<button onClick={() => userStore.login()}><i className="fas fa-sign-in-alt"></i></button>);
    const LogoutBtn = (<button onClick={() => userStore.logout()}><i className="fas fa-sign-out-alt"></i></button>);

    return <header className="header">
        <nav id="main-nav">
          <div className="container">
            <div><Link to="/">Home</Link></div>
            <div>{ userStore.isLogged() ? userStore.client.userAccount : ''} { process.env.REACT_APP_VERSION }</div>
            <div className="btn-login">{ userStore.isLogged() ? LogoutBtn: LoginBtn}</div>
          </div>
        </nav>

        
      </header>
});

export default Navigation;
