import React from "react";
import { GameProps } from "../../../components/GameView";
import { timeHourToString } from "../../../model/Tools";
import NftPandaContract from "../model/NftPandaContract";

const NftPandaBotView: React.FunctionComponent<GameProps> = props => {

  const contract = props.game as NftPandaContract;
  
  const slots = Array.from(contract.slots.values()).map((slot) => {
    return (
        <div key={slot.asset_id} className="panda-slot">
          <p>Slot enery: { slot.energy/100 + '/100'} timeLeft: { timeHourToString(contract.getTimeleft(slot)) }</p>
          <p>Level : { slot.lvl } Steps: { slot.steps } finalnumber : { slot.finalnumber }</p>
        </div>
      )
  });
  return (
    <div>      
      { slots }
    </div>
  )
}

export default NftPandaBotView;