import { AnnotationsMap, makeObservable, observable, runInAction } from "mobx";
import { HarvestAsset } from "./contract/GameContract";
import UserStore from "./UserStore";

export default class Bot {

  private _harvestItems: HarvestAsset[];
  private _mainLoopId: NodeJS.Timeout|undefined;
  private _lastUpdate: Date;
  private _loopTimeMs:number;
  private _isReady:boolean;

  constructor() {
    
    this._harvestItems = [];
    this._lastUpdate = new Date();
    this._loopTimeMs = 10 * 1000;
    this._isReady = false;

    makeObservable(this, {
      _lastUpdate: observable,
      _isReady: observable,
    } as AnnotationsMap<this, string>);

  }

  public async init() {
    const userStore = UserStore.getInstance();

    await userStore.gameStore.loadGameAssets();
    // await this.updateHarvestItems();

    runInAction(()=> {
      this._isReady = true;
    })

  }

  public get isReady():boolean {
    return this._isReady;
  }

  public get games() {
    return UserStore.getInstance().gameStore.games
  }

  public async startWatch() {
    if (this._mainLoopId) {
      clearInterval(this._mainLoopId);
    }
    const userStore = UserStore.getInstance();
    this._mainLoopId = setInterval(async ()=> {
      console.log('runing bot');
      await userStore.gameStore.autoActions();
      runInAction(async ()=> {
        this._lastUpdate = new Date();
      })
    }, this._loopTimeMs);
    await userStore.gameStore.autoActions();    
  }

  public stopWatch() {
    console.log('stop bot');
    if (this._mainLoopId) {
      clearInterval(this._mainLoopId);
      runInAction(async ()=> {
        this._isReady = false;
      })

    }
  }

  public get lastUpdate(): Date {
    return this._lastUpdate;
  }

  public async updateHarvestItems() {
    const userStore = UserStore.getInstance();
    this._harvestItems = await userStore.gameStore.getHarvestItems();
  }

  public get harvestItems(): HarvestAsset[] {
    return this._harvestItems;
  }
 
}