import { AnnotationsMap, makeObservable, observable } from "mobx";
import GameBot from "./GameBot";
import EOSClient from "./RPCClient";

export default class GameBotStore {

    private _client: EOSClient;
    private _bots: Map<string, GameBot>;
    
    constructor(client:EOSClient) {

        this._client = client;

        this._bots = new Map();

        makeObservable(this, {
          _bots: observable,
        } as AnnotationsMap<this, string>);
    }

    public get bots() {
      return this._bots;
    }

    public addBot(bot:GameBot) {
      this._bots.set(bot.game.name, bot);
    }

    public async startSelectedBots() {
      console.log('----------------- startSelectedBots');
      const keys = Array.from(this._bots.keys());
      for (let name of keys) {
        const bot = this._bots.get(name);
        if (bot?.game.isSelectedForBot) {
          await bot.init();
          await bot.startWatch(); 
        }
      }
    }
  
    public async stopSelectedBots() {
      console.log('----------------- stopSelectedBots');
      const keys = Array.from(this._bots.keys());
      for (let name of keys) {
        const bot = this._bots.get(name);
        if (bot?.isRunning) {
          await bot?.stopWatch();
        }
      }
  
    }
  }