import React from 'react';
import './App.scss';
import { observer } from "mobx-react-lite";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from './pages/Home';
import Login from './pages/Login';
import FarmingTaleView from './games/farmingtales/view/FarmingTaleView';
import Navigation from './components/Navigation';
import FarmersWorldView from './games/farmersworld/view/FarmersWorldView';
import Bots from './pages/Bots';
import UserStore from "./model/UserStore";


// const App = observer(({ skynet, connectivityProvider, debugComponents, history = createBrowserHistory() }: AppProps): React.ReactElement => {
const App = observer((): React.ReactElement => {
  // const userStore = UserStore.getInstance()
  // useEffect(() => {
  //   userStore.login();
  // }, [])
  
  return (
    <BrowserRouter>
    <div className="App fh">
      <div className="fh">
      <Navigation />
      { UserStore.getInstance().isLogged() ?
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/bot" element={<Bots></Bots>} />
        <Route path="users/*" element={<Login></Login>} />
        <Route path="ft/*" element={<FarmingTaleView></FarmingTaleView>} />
        <Route path="fw/*" element={<FarmersWorldView></FarmersWorldView>} />
      </Routes>
      :
      <Login></Login>
    }
    </div>
    </div>
  </BrowserRouter>

  )
});

export default App;

