import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import UserStore from "../model/UserStore";
import GameBot from "../model/GameBot";
import BotView from "../components/BotView";


const Bots = observer((): React.ReactElement => {
  const userStore = UserStore.getInstance();
  // const [bot, setBot] = useState<Bot>(new Bot());

  useEffect(() => {
    if (userStore.isLogged()) {
      const updateGame = async () => {
        //await userStore.defaultBot.init()
        //userStore.defaultBot.startWatch();
        userStore.startSelectedBots();
        //await bot.init()
        //bot.startWatch();
      };
      updateGame();
    }
    return () => {
      userStore.stopSelectedBots();
      //userStore.defaultBot.stopWatch();
      /*
      if (bot) {
        bot.stopWatch();
      }
      */
    }
  }, [userStore]);

  const botStore = userStore.botStore;
  const bots = Array.from(botStore.bots.values()).map((bot: GameBot) => {
    if (bot.game.isSelectedForBot) {
      return <BotView key={bot.game.name} bot={bot} />
    }
    return <div key={bot.game.name}></div>;
  });
  const pctCPU = Math.floor(userStore.getCPUUsagePercent()!*10000)/100;
  return (
      <div className="container bot">
        <h3>List of bots running CPU : {pctCPU}%</h3>
        <div className="bot-content">
          { bots }
        </div>          
    </div>
  );

});

export default Bots;
