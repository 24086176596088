import React, { useEffect, useState } from "react";
import UserStore from "../../../model/UserStore";
import { observer } from "mobx-react-lite";
import FarmersWorldContract from "../model/FarmersWorldcontract";
import ToolsView from "./ToolsView";
import MembersView from "./MemberView";

const FarmersWorldView = observer((): React.ReactElement => {
  const userStore = UserStore.getInstance();
  const [game, setGame] = useState(new FarmersWorldContract(userStore.client));

  useEffect(() => {
    if (userStore.isLogged()) {

      const updateGame = async () => { 
        const game = new FarmersWorldContract(userStore.client);
        await game.loadAssets();
        setGame(game);  
      }
      updateGame();

      
      /*
      setInterval(() => {
        console.log("calculate time left");
        getTimeLeft("1099571126880", 2 * 3600 + 10 * 60, "cow");
        getTimeLeft("1099571126884", 3 * 3600, "sheep");  
      }
      , 60000); // 10334
      */
    }
  }, [userStore]);

  return (
    <div>
      <h1>FarmersWorld</h1>
      {userStore.isLogged() ? (
        <div>
            <ToolsView game={game}></ToolsView>
            <MembersView game={game}></MembersView>
        </div>
      ) : (
        "Must loggin"
      )}
    </div>
  );
});

export default FarmersWorldView;
