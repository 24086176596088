import { AtomicAsset } from "../../../model/atomic/AtomicAssetClient";
import GameContract, { TransactionAction } from "../../../model/contract/GameContract";
import EOSClient from "../../../model/RPCClient";

/**
 * https://wax.bloks.io/account/xpansiongame
 * 
      xpansiongame
      renterclaim
        equipment_asset_id: 1099570258538
        owner: 1puqy.wam
        renter: qwnho.wam

      xpansiongame
        renterclaim
          equipment_asset_id: 1099570261835
          owner: qwnho.wam
          renter: qwnho.wam        

  
 * 
 * 
 */

export interface LandSX {
  land_id: number,
  land_type: number,
  land_rarity: number,
  planet_id: number,
  x: number,
  y: number,
  owner: string,
  rent_fee: number,
  rent_list: string[]
}

export interface RentAsset {
  equipment_asset_id: string,
  renter: string,
  create_time: number,
  mint_time: number,
  land_id: number,
  asset_id: number,
  settlement_type: number,
  land_rarity: number,
  land_type: number,
  rent_fee: number,
  asset?: AtomicAsset
}

 export default class XpansionContract extends GameContract {

  static NAME = 'xpansiongame';
  static CONTRACT_NAME = 'xpansiongame';
  static COLLECTION_NAME = 'xpansiongame';
  static TOKEN_CONTRACT = 'xpsgametoken';

  protected _coolDownTimeInSeconds: number = 3600;
  protected _rentAssets: RentAsset[];

  constructor(client:EOSClient) {
    super(client);
    // console.log('[FarmersWorldContract] constructor');

    this._name = XpansionContract.NAME;
    this._tokenContract = XpansionContract.TOKEN_CONTRACT;
    this._defaultSymbol = 'XPS';

    this._rentAssets = [];

  }

  public loadAssets = async (): Promise<GameContract> => {
    await this.loadRentmint();
    super.loadAssets();
    return this;
  }

  public updateAssets = async():Promise<GameContract> => {
    await this.loadRentmint();
    super.updateAssets();
    return this;
  }  

  public async autoActions(): Promise<TransactionAction[]> {
    await this.updateAssets();
    const client = this._client;
    const actions: TransactionAction[] = [];
    // check if land rented can be harvested 
    for (let asset of this._rentAssets) {
      const timeLeft = this.getTimeLeft(asset.mint_time);
      if (timeLeft < 0) {
        const landSX = await this.loadLandSXById(asset.land_id);
        const owner = landSX?.owner === '' ? client.userAccount:landSX?.owner;
        actions.push(this.createGameActions(
          XpansionContract.NAME,
          client.userAccount,
          "renterclaim",
          {
            equipment_asset_id: asset.equipment_asset_id,
            owner: owner,
            renter: client.userAccount
          }          
        ));  

      }
    }


    return actions;
  }

  public get rentAssets() {
    return this._rentAssets;
  }

  public getTimeLeft(time:number) {
    const now = new Date().getTime() / 1000;
    const dt = (time + this._coolDownTimeInSeconds)-now;
    return Math.round(dt);
  }

  protected async loadOwnermint() {

  }

  /**
   */
  protected async loadRentmint() {
        // qwnho.wam
        const client = this._client;
        try {
          const results = await client.rpc.get_table_rows({
            json: true,
            code: XpansionContract.CONTRACT_NAME,
            scope: client.userAccount,
            table: 'rentmint',
            limit: 4000,
            reverse: false,
            show_payer: false
          });
          const rows = results['rows'];
          this._rentAssets = rows;
          // table landsx -> land id 
          // land_id (key) planet_id	x	y	owner	asset_id	settlement_type	land_rarity	land_type	rent_fee	rent_status	rent_list	stake_tax	stake_tax_update	stake_tax_duration	stakers	avai_balance	stake_balance	claim_time	stake_time
          /*
          const atomicClient = new AtomicAssetClient();
          for (let asset of this._rentAssets) {
            if (asset.asset_id !== 0) {
              console.log(`must get owner for ${asset.asset_id}`);
              //   //   https://wax.api.atomicassets.io/atomicassets/v1/assets?ids=1099586858971,0&order=desc&sort=asset_id&collection_name=xpansiongame&schema_name=lands.kl22b&limit=20000
              const params: AtomicAssetParam = {
                ids: [asset.asset_id, 0].join(','),
                collection_name: 'xpansiongame',
                schema_name: 'lands.kl22b',
                limit: 20000,
                order: 'desc',
                sort: 'asset_id'
              }
              const assets = await atomicClient.loadAssets(params);
              console.log(assets);
            }
          }
          */
        }
        catch (e) {
          console.error(e);
        }   
  }

  public async loadLandSXById(land_id:number):Promise<LandSX|undefined> {
    try {
      const results = await this._client.rpc.get_table_rows({
        json: true,
        code: XpansionContract.CONTRACT_NAME,
        scope: XpansionContract.CONTRACT_NAME,
        table: 'landsx',
        lower_bound: land_id,
        limit: 1,
        reverse: false,
        show_payer: false
      });
      const rows = results['rows'];
      return rows[0];
    }
    catch (e) {
      console.error(e);
    }   
    return;
  }
  
}